<template>

  <div class="py-4 px-6 relative text-gray-600">
    <!--头像、昵称-->
    <div class="flex">
      <img alt="" class="w-20 h-20" :src="this.$store.state.webInfo.logo">
      <div class="ml-4 pt-4">
        <h1 class="text-2xl font-semibold tracking-[0.2em]">{{ $store.state.webInfo.name }}</h1>
        <p class="text-sm mt-1">{{ $store.state.webInfo.address }}</p>
      </div>
    </div>

    <!--分割-->
    <div class="divider my-0"/>

    <!--座右铭-->
    <p class="text-base">{{ $store.state.webInfo.sign }}</p>

    <!--菜单-->
    <div class="flex justify-between my-4">
      <router-link v-for="item in menuList"
                   :to="item.url"
                   :data-tip="item.name"
                   class="p-2 tooltip shadow rounded-lg border-2 cursor-pointer duration-100 bg-white border-gray-100 hover:border-blue-400 hover:text-blue-400">
        <i :class="item.icon+' text-3xl pl-[0.2rem]'"/>
      </router-link>
    </div>

    <!--站长简介-->
    <div class="max-md:hidden border-t-4">
      <md-preview v-model="this.$store.state.webInfo.custom" previewTheme="github" :codeFoldable="false"/>
    </div>

    <!--版权-->
    <div class="absolute bottom-4 w-[calc(100%-2.5rem)] text-center max-md:hidden">
      <div class="divider my-0"/>
      <p class="text-gray-400 mb-2">{{ $store.state.webInfo.copyright }}</p>
      <p class="text-xs tracking-tight">
        <a class="text-gray-300" target="_blank" href="https://beian.miit.gov.cn/">{{ $store.state.webInfo.icp }}</a>
      </p>
      <p class="text-xs tracking-tight" v-if="this.$store.state.webInfo.gongan">
        <a class="text-gray-300 flex justify-center" target="_blank"
           :href="'https://beian.mps.gov.cn/#/query/webSearch?code='+this.$store.state.webInfo.gongan">
          <img src="../../assets/img/gongan.png" class="w-4	h-4" alt=""/>
          {{ $store.state.webInfo.gongan }}
        </a>
      </p>
    </div>
  </div>

</template>

<script>
import { MdPreview } from 'md-editor-v3';

export default {
  name: "ULeft",
  components:{MdPreview},
  data() {
    return {
      progressValue: 0,
      musicPlay: false,
      musicList: [],
      menuList: [
        {name: '首页', icon: 'ri-home-3-line', url: '/'},
        {name: '归档', icon: 'ri-archive-line', url: '/archives'},
        {name: '相册', icon: 'ri-landscape-line', url: '/photo'},
        {name: '友链', icon: 'ri-links-line', url: '/link'},
      ]
    }
  },
  methods: {
    //-----------------音乐---------------
    //开始/暂停播放
    musicBtn() {
      this.musicPlay ? this.$refs.audio.play() : this.$refs.audio.pause();
    },
    //计算音乐播放%
    musicTimeUpdate(e) {
      this.progressValue = e.target.currentTime / e.target.duration * 100;
    },
    //改变进度
    rangeChange(e) {
      let audio = this.$refs.audio;
      audio.pause();
      audio.currentTime = this.progressValue * audio.duration / 100
      audio.play();
      this.musicPlay = true;
    },
    //------------------音乐end------------
  }
}
</script>

<style scoped>
input[type="range"]::-webkit-slider-thumb {
  width: 0;
}

/deep/.md-editor-preview-wrapper{
  padding: 0 !important;
}
</style>
