import {createApp} from 'vue';
import Toast from "@/components/Toast";
import Compressor from 'compressorjs';

//时间戳转化
export function conversionMs(date) {
    const now = new Date();
    const seconds = (now - new Date(date)) / 1000;

    if (seconds < 60) {
        return '刚刚';
    }
    if (seconds < 3600) {
        return Math.floor(seconds / 60) + '分钟前';
    }
    if (seconds < 3600 * 24) {
        return Math.floor(seconds / 3600) + '小时前';
    }
    if (seconds < 3600 * 24 * 30) {
        return Math.floor(seconds / (3600 * 24)) + '天前';
    }
    if (seconds < 3600 * 24 * 30 * 12) {
        return Math.floor(seconds / (3600 * 24 * 30)) + '月前';
    } else {
        let d = new Date(date);
        return d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate();
    }
}

//toast组件
export function showToast(message, type) {
    const ToastComponent = createApp(Toast);
    let toastInstance = ToastComponent.mount(document.createElement('div'));
    document.body.appendChild(toastInstance.$el);
    toastInstance.showToast(message, type);
}

//图片压缩
export function compressImage(file) {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.6,
            // maxWidth: 400,
            maxHeight: 400,
            success(result) {
                let file = new File([result], result.name, {type: result.type,});
                resolve(file);
            },
            error(err) {
                reject(err);
            },
        });
    });
}
